

const ContactUs = () => {
    return (
        <>
            <div className="contact">
                <div class="text-box" >
                    <h2 ><strong><span>Contact Us</span></strong></h2>
                    <p ><strong><span >If you want to contact us regarding any suggestion or help then simply use our mail address.Here you just have to mail to our employees for any of the your support. All possible help will be made soon via mail account. So don't waste your time if you have any issue in using sattaking.vip Thank You</span></strong></p>
                    <p ><strong><span >Mail Address - kingkingdurjay@gmail.com</span></strong></p> </div>
            </div>
        </>

    )
}

export default ContactUs;