
const AboutUs = () => {
    return (
        <>
        <div className="about">
        <div class="text-box">
            <h1 ><span ><strong>About Us</strong></span></h1>
            <p ><strong><a href="/">Satta King </a> is a free portal where we publish all type of Satta companies results like - Gali, Desawar, Faridabad, Ghaziabad, Shree Ganesh, Kashipur, Bahadurgarh, Ahmedabad, Kanpur, Golden, etc. Here you can check the month-wise result chart also for getting the estimate of the entire data.</strong></p>
            <p ><strong>In this portal you can also check mobile numbers of all khaiwals, you can use these number to get the leaked number. But our website doesn't have any connection with them, you can give your money time to them on your own risk. For any type of queries, you can <a href="/">contact us</a> by visiting the given page.</strong></p>
         </div>
          </div>
        </>
    )
}

export default AboutUs


/*

*/