import React from 'react';
function FooterButton() {

    return (
        <div className="footerButton">
            <a href='/disclaimer' >DISCLAIMER &nbsp;</a>|
            <a href='/privacypolicy' >PRIVACY POLICY</a>|
            <a href='/about' >ABOUT US &nbsp;</a>|
            <a href='/contact' >CONTACT &nbsp;</a>|
            

            
        </div>
    );
}

export default FooterButton;
